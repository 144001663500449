export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_ACCOUNT_SERVICE_VIEW = 'ROLE_ACCOUNT_SERVICE_VIEW'
export const ROLE_SETUP_SITE_VIEW = 'ROLE_SETUP_SITE_VIEW'
export const ROLE_SETUP_SITE_CREATE = 'ROLE_SETUP_SITE_CREATE'
export const ROLE_SETUP_SITE_EDIT = 'ROLE_SETUP_SITE_EDIT'
export const ROLE_SETUP_DISTRICT_EDIT = 'ROLE_SETUP_DISTRICT_EDIT'
export const ROLE_SETUP_DISTRICT_CREATE = 'ROLE_SETUP_DISTRICT_CREATE'
export const ROLE_SETUP_DISTRICT_VIEW = 'ROLE_SETUP_DISTRICT_VIEW'
export const ROLE_INTERNAL_USER = 'ROLE_ILLUMINATE_EMPLOYEE'
/**
 * @deprecated - use ROLE_INTERNAL_USER instead.
 */
export const ROLE_ILLUMINATE_EMPLOYEE = ROLE_INTERNAL_USER
export const ROLE_USER_MANAGEMENT_EDIT = 'ROLE_USER_MANAGEMENT_EDIT'
export const ROLE_USER_MANAGEMENT_CREATE = 'ROLE_USER_MANAGEMENT_CREATE'
export const ROLE_USER_VIEW = 'ROLE_USER_VIEW'
export const ROLE_USER_EDIT = 'ROLE_USER_EDIT'
export const ROLE_USER_CREATE = 'ROLE_USER_CREATE'
export const ROLE_USER_ROLE_VIEW = 'ROLE_USER_ROLE_VIEW'
export const ROLE_USER_ROLE_EDIT = 'ROLE_USER_ROLE_EDIT'
export const ROLE_USER_ROLE_CREATE = 'ROLE_USER_ROLE_CREATE'
export const ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE = 'ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE'
export const ROLE_SETUP_SIS_IMPORTS_TEMPLATES_EDIT = 'ROLE_SETUP_SIS_IMPORTS_TEMPLATES_EDIT'
export const ROLE_SETUP_SIS_IMPORTS_API_EDIT = 'ROLE_SETUP_SIS_IMPORTS_API_EDIT'
export const ROLE_SETUP_SIS_IMPORTS_API_VIEW = 'ROLE_SETUP_SIS_IMPORTS_API_VIEW'
export const ROLE_IMT_AUTHENTICATIONS_VIEW = 'ROLE_IMT_AUTHENTICATIONS_VIEW'
export const ROLE_IMT_AUTHENTICATIONS_EDIT = 'ROLE_IMT_AUTHENTICATIONS_EDIT'
export const ROLE_IMT_AUTHENTICATIONS_CREATE = 'ROLE_IMT_AUTHENTICATIONS_CREATE'
export const ROLE_IMT_AUTHENTICATIONS_CREATE_ILLUMINATE_GOOGLE =
    'ROLE_IMT_AUTHENTICATIONS_CREATE_ILLUMINATE_GOOGLE'
export const ROLE_SETUP_EXPORT_PRODUCT_VIEW = 'ROLE_SETUP_EXPORT_PRODUCT_VIEW'
export const ROLE_SETUP_EXPORT_PRODUCT_EDIT = 'ROLE_SETUP_EXPORT_PRODUCT_EDIT'
export const ROLE_SETUP_EXPORT_PRODUCT_CREATE = 'ROLE_SETUP_EXPORT_PRODUCT_CREATE'
export const ROLE_IMT_CLIENTS_VIEW = 'ROLE_IMT_CLIENTS_VIEW'
export const ROLE_IMT_CLIENTS_EDIT = 'ROLE_IMT_CLIENTS_EDIT'
export const ROLE_IMT_CLIENTS_CREATE = 'ROLE_IMT_CLIENTS_CREATE'
export const ROLE_IMT_INSTANCES_VIEW = 'ROLE_IMT_INSTANCES_VIEW'
export const ROLE_IMT_INSTANCES_EDIT = 'ROLE_IMT_INSTANCES_EDIT'
export const ROLE_IMT_INSTANCES_CREATE = 'ROLE_IMT_INSTANCES_CREATE'
export const ROLE_IMT_INSTANCES_DEPLOY = 'ROLE_IMT_INSTANCES_DEPLOY'
export const ROLE_IMT_INSTANCES_ACTIVATE = 'ROLE_IMT_INSTANCES_ACTIVATE'
export const ROLE_IMT_INSTANCES_DEACTIVATE = 'ROLE_IMT_INSTANCES_DEACTIVATE'
export const ROLE_IMT_INSTANCES_ARCHIVE = 'ROLE_IMT_INSTANCES_ARCHIVE'
export const ROLE_IMT_INSTANCES_DELETE = 'ROLE_IMT_INSTANCES_DELETE'
export const ROLE_IMT_INSTANCES_RESTORE = 'ROLE_IMT_INSTANCES_RESTORE'
export const ROLE_IMT_INSTANCES_SANDBOX_CREATE = 'ROLE_IMT_INSTANCES_SANDBOX_CREATE'
export const ROLE_IMT_INSTANCES_SUPER_ADMIN = 'ROLE_IMT_INSTANCES_SUPER_ADMIN'

export default {
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_ILLUMINATE_EMPLOYEE,
    ROLE_ACCOUNT_SERVICE_VIEW,
    ROLE_USER_MANAGEMENT_EDIT,
    ROLE_USER_MANAGEMENT_CREATE,
    ROLE_USER_VIEW,
    ROLE_USER_EDIT,
    ROLE_USER_CREATE,
    ROLE_SETUP_SITE_VIEW,
    ROLE_SETUP_SITE_CREATE,
    ROLE_SETUP_SITE_EDIT,
    ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE,
    ROLE_SETUP_SIS_IMPORTS_TEMPLATES_EDIT,
    ROLE_SETUP_SIS_IMPORTS_API_EDIT,
    ROLE_SETUP_SIS_IMPORTS_API_VIEW,
    ROLE_IMT_AUTHENTICATIONS_VIEW,
    ROLE_IMT_AUTHENTICATIONS_EDIT,
    ROLE_IMT_AUTHENTICATIONS_CREATE,
    ROLE_IMT_AUTHENTICATIONS_CREATE_ILLUMINATE_GOOGLE,
    ROLE_IMT_CLIENTS_VIEW,
    ROLE_IMT_CLIENTS_EDIT,
    ROLE_IMT_CLIENTS_CREATE,
    ROLE_IMT_INSTANCES_VIEW,
    ROLE_IMT_INSTANCES_EDIT,
    ROLE_IMT_INSTANCES_CREATE,
    ROLE_SETUP_DISTRICT_EDIT,
    ROLE_SETUP_DISTRICT_CREATE,
    ROLE_SETUP_DISTRICT_VIEW,
    ROLE_IMT_INSTANCES_DEPLOY,
    ROLE_IMT_INSTANCES_ARCHIVE,
    ROLE_IMT_INSTANCES_ACTIVATE,
    ROLE_IMT_INSTANCES_DEACTIVATE,
    ROLE_IMT_INSTANCES_DELETE,
    ROLE_IMT_INSTANCES_RESTORE,
    ROLE_IMT_INSTANCES_SANDBOX_CREATE,
    ROLE_IMT_INSTANCES_SUPER_ADMIN,
    ROLE_USER_ROLE_VIEW,
    ROLE_USER_ROLE_EDIT,
    ROLE_USER_ROLE_CREATE,
}
