import { humanizeStr } from '@/helpers/form/formatting'

const defaultValues = {
    props: {
        form: {
            type: Object,
            default: () => ({
                data: {},
                rules: {},
                errors: [],
            }),
        },
        fieldKey: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        caseInsensitiveMatch: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: String,
            default: 'auto',
        },
    },
    computed: {
        getLabel() {
            return this.label ? this.label : humanizeStr(this.fieldKey)
        },
        value: {
            get() {
                return this.caseInsensitiveMatch
                    ? this.form.data[this.fieldKey]?.toLowerCase()
                    : this.form.data[this.fieldKey]
            },
            set(val) {
                this.form.data[this.fieldKey] = this.$attrs.type === 'number' ? parseInt(val) : val
            },
        },
    },
    methods: {
        clearErrors() {
            delete this.form.errors[this.fieldKey]
        },
    },
}

export default defaultValues
