<template>
    <label class="text-field v-label theme--light" :for="fieldKey"
        ><span class="label-wrap">{{ getLabel }}</span>
        <v-textarea
            :id="fieldKey"
            :key="fieldKey"
            :value="formValue"
            v-bind="$attrs"
            :rules="formRules"
            :error-messages="form.errors[fieldKey]"
            outlined
            v-on="$listeners"
            @input="(v) => input(v)"
        >
        </v-textarea>
    </label>
</template>

<script>
import { humanizeStr } from '@/helpers/form/formatting'

export default {
    // two word ;)
    name: 'TextArea',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        // backward compatibility forms
        form: {
            type: Object,
            default: () => ({
                data: null,
                rules: {},
                errors: [],
            }),
        },
        fieldKey: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        resetValidation: {
            type: Function,
            default: () => {
                return () => {}
            },
        },
        // new forms
        value: {
            type: String,
            default: '',
        },
        rules: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        getLabel() {
            return this.label ? this.label : humanizeStr(this.fieldKey)
        },
        formRules() {
            if (this.rules) {
                return this.rules
            }
            return this.form.rules[fieldKey] ?? []
        },
        formValue() {
            if (this.form.data) {
                return this.form.data[this.fieldKey]
            }
            return this.value
        },
    },
    methods: {
        input(v) {
            if (this.form.data) {
                this.form.data[this.fieldKey] = this.$attrs.type === 'number' ? parseInt(v) : v
                delete this.form.errors[this.fieldKey]
            }
            this.$emit('input', v)
        },
    },
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

/** at least this looks fine on desktop for now. */
.text-field {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;
        }
    }
    .label-wrap {
        // This is the width of the longest
        width: 150px;
        line-height: 16px;
        font-size: 14px;
    }
}
</style>
