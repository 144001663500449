<template>
    <v-dialog :value.sync="value" :max-width="maxWidth" v-bind="$attrs" v-on="$listeners">
        <!-- supporting vuetify scoped slots -->
        <template v-for="(_, name) in $scopedSlots" #[name]="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>

        <v-card class="mx-auto" :loading="saving || loading">
            <slot name="header">
                <v-card-title>
                    <span>{{ title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" icon @click="input(false)">
                        <v-icon data-cy="close-modal-icon"> fal fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
            </slot>
            <slot name="content" />
            <slot name="footer">
                <v-divider />
                <v-card-actions>
                    <v-btn
                        v-if="showBackAction"
                        :disabled="disabled"
                        outlined
                        color="primary"
                        @click="backAction"
                    >
                        Back
                    </v-btn>
                    <slot name="actions"></slot>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!alert"
                        :disabled="disabled"
                        text
                        data-cy="close-modal-btn"
                        @click="input(false)"
                        >{{ viewOnly ? 'Close' : 'Cancel' }}
                    </v-btn>
                    <v-btn
                        v-if="!viewOnly && (!submitRoles || hasRole(submitRoles))"
                        :color="confirmButtonColor"
                        :class="{ 'white--text': confirmButtonColor === 'danger' }"
                        :disabled="disabled || disableConfirm"
                        @click="confirm"
                    >
                        <v-icon v-if="confirmIcon" left>{{ confirmIcon }} </v-icon>
                        {{ confirmText }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'BaseDialog',
    model: {
        prop: 'value',
        event: 'input',
    },
    emits: ['input'],
    props: {
        alert: { type: Boolean, default: false },
        backAction: { type: Function, default: () => false },
        closeOnSubmit: { type: Boolean, default: true },
        confirmAction: { type: Function, default: () => {} },
        confirmIcon: { type: String, default: null },
        confirmText: { type: String, default: 'Submit' },
        disabled: { type: Boolean, default: false },
        disableConfirm: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        maxWidth: { type: String, default: '600px' },
        showBackAction: { type: Boolean, default: false },
        submitRoles: { type: Array, default: null },
        title: { type: String, default: 'Console' },
        viewOnly: { type: Boolean, default: false },
        value: { type: Boolean, default: false },
        confirmButtonColor: { type: String, default: 'primary' },
    },
    data: () => ({ saving: false }),
    computed: {
        ...mapGetters({ hasRole: 'auth/hasRole' }),
    },
    watch: {
        opened(value) {
            this.$emit('input', value)
            if (!value) {
                //emitting close event because vuetify doesn't have one for dialogs
                this.$emit('close')
            }
        },
    },
    methods: {
        input(v) {
            this.$emit('input', v)
            this.$emit('close')
        },
        confirm: async function () {
            this.saving = true
            await this.confirmAction()
            this.saving = false
            if (this.closeOnSubmit) {
                this.input(false)
            }
        },
    },
}
</script>
