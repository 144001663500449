<template>
    <base-dialog
        :value="value === undefined ? iValue : value"
        :title="title"
        max-width="100%"
        confirm-text="Save"
        :confirm-action="save"
        :loading="loading"
        :disable-confirm="saveDisabled"
        scrollable
        @input="input"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>

        <template v-slot:content>
            <v-card-text>
                <v-container fluid class="pa-10">
                    <district-form
                        ref="districtForm"
                        :edit="edit"
                        @formValid="(v) => (valid = v)"
                    />
                </v-container>
            </v-card-text>
        </template>
    </base-dialog>
</template>

<script>
import BaseDialog from '@/components/modal/BaseDialog'
import DistrictForm from '@/views/DistrictManagement/DistrictForm'
import { mapGetters } from 'vuex'
import { ROLE_SETUP_DISTRICT_EDIT } from '@/helpers/security/roles'

export default {
    name: 'DistrictEditModal',
    components: { BaseDialog, DistrictForm },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        title: {
            type: String,
            default: 'Distirct',
        },
        value: {
            type: Boolean,
            default: undefined,
        },
        backOnClose: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            iValue: false,
            valid: undefined,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'client/getFetching',
            hasRole: 'auth/hasRole',
        }),
        hasDistrictEditRole() {
            return this.hasRole(ROLE_SETUP_DISTRICT_EDIT)
        },
        saveDisabled() {
            return !this.hasDistrictEditRole || !this.valid
        },
    },
    methods: {
        async save() {
            const resp = this.edit
                ? await this.$refs.districtForm.submitEdit()
                : await this.$refs.districtForm.submitNew()
        },
        input(v) {
            this.iValue = v
            this.$emit('input', v)
            if (this.backOnClose) this.$router.go(-1)
        },
    },
}
</script>
<style scoped></style>
