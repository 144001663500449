<template>
    <label class="v-label theme--light text-field">
        <span class="label-wrap">{{ getLabel }}</span>
        <v-autocomplete
            v-bind="$attrs"
            return-object
            outlined
            cache-items
            :items="items"
            :item-text="display"
            :value="value"
            item-value="id"
            :search-input.sync="search"
            :loading="fetching"
            class="text-field"
            @input="update"
        >
            <template #prepend> </template>
            <template #no-data>
                <v-list-item>
                    <v-list-item-title> Search for a client... </v-list-item-title>
                </v-list-item>
            </template>
        </v-autocomplete>
    </label>
</template>

<script>
import { mapGetters } from 'vuex'
import labelMixin from './labelMixin'
import clientApi from '@/api/imt/clients'

export default {
    mixins: [labelMixin],
    inheritAttrs: false,
    props: {
        // https://github.com/vuejs/vue-web-component-wrapper/issues/30#issuecomment-427350734
        ...labelMixin.props,
        value: {
            type: Object, // client object.
            default: null,
        },
    },
    data: () => ({
        search: null,
        searchDebounce: null,
        items: [],
    }),
    computed: {
        ...mapGetters({
            clients: 'client/getItems',
            fetching: 'client/getFetching',
        }),
    },
    watch: {
        async search(val) {
            //dont search for the same thing twice
            if (this.display(this.value) === val) {
                return
            }

            clearTimeout(this.debounceFilter)

            this.searchDebounce = setTimeout(async () => {
                if (!val || val.length < 1) {
                    return
                }

                const { data } = await clientApi.cget({ search: { name: val } })
                this.items = data.items
            }, 500)
        },
        value() {
            this.$emit('input', this.value)
        },
    },
    mounted() {
        this.items = this.clients
    },
    methods: {
        display: function (v) {
            return v ? `${v.displayName} (${v.id})` : ''
        },
        update: function (newValue) {
            this.$emit('input', newValue)
        },
    },
}
</script>

<style lang="scss" scoped>
.text-field {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;
        }
    }
    .label-wrap {
        width: 150px;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}
</style>
