<template>
    <v-flex class="date-picker">
        <slot name="label">
            <label class="v-label theme--light date-picker__label" :style="{ width: labelWidth }">
                <span :class="required ? 'required' : ''">{{ label }}</span>
            </label>
        </slot>
        <v-select
            v-bind="$attrs"
            :value="value"
            :items="schoolYears"
            item-value="value"
            item-text="label"
            dense
            outlined
            hide-details="auto"
            prepend-inner-icon="fal fa-calendar-alt"
            class="date-picker__input"
            v-on="$listeners"
        />
    </v-flex>
</template>

<script>
import { getYearObjects, formatYear } from '@/helpers/shared.js'

export default {
    name: 'SchoolYearPicker',
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        highYear: {
            type: Number,
            default: new Date().getFullYear(),
        },
        numYears: {
            type: Number,
            default: 10,
        },
        required: {
            type: Boolean,
            default: false,
        },
        labelWidth: {
            type: String,
            default: '150px',
        },
    },
    computed: {
        schoolYears: function () {
            const years = getYearObjects(this.highYear, this.numYears)
            // if there is a non-empty value, make sure that that value is on the list of options.
            if (this.value) {
                // using == because this is a string in the API
                const exists = years.find((y) => y.value == this.value)
                if (!exists) {
                    // This will preserve the value in the list that is currently entered.
                    // this is helpful for ensuring that the value that appears is the value in the db.
                    const label = parseInt(this.value)
                        ? formatYear(parseInt(this.value))
                        : this.value
                    years.push({ label: label, value: this.value })
                }
            }

            return years
        },
    },
}
</script>

<style lang="scss" scoped>
.date-picker {
    display: flex;

    &__label {
        flex: 0 0 auto;
        align-items: center;
        line-height: 16px;
        font-size: 14px;
        display: flex;
    }

    &__input {
        flex: 1 1 auto;
    }
}
</style>
