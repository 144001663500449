<template>
    <label class="v-label theme--light auto-complete-field">
        <span class="label-wrap">{{ getLabel }}</span>
        <v-autocomplete
            :id="fieldKey"
            :key="fieldKey"
            v-model="value"
            v-bind="$attrs"
            :rules="form.rules[fieldKey]"
            :error-messages="form.errors[fieldKey]"
            outlined
            :hide-details="hideDetails"
            v-on="$listeners"
            @input="clearErrors"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
            </template>
            <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
        </v-autocomplete>
    </label>
</template>

<script>
import formFieldDefaultMixin from '@/components/form/formFieldDefaultMixin'

export default {
    name: 'AutoComplete',
    mixins: [formFieldDefaultMixin],
}
</script>
<style lang="scss" scoped>
.auto-complete-field {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;
        }
        .v-icon {
            font-size: 14px;
        }
    }
    .label-wrap {
        width: 150px;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}
</style>
