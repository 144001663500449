<template>
    <div class="district-edit">
        <extended-form
            ref="formRef"
            :form-ready="form.ready"
            :form-data="form.data"
            :submit="edit ? submitEdit : submitNew"
            submit-text="Save"
            :show-submit="false"
            v-on="$listeners"
        >
            <v-row>
                <v-col md="6" cols="12">
                    <v-col class="pb-0">
                        <h5>District Details</h5>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            disabled
                            :form="form"
                            hide-details="auto"
                            field-key="name"
                            label="Client Legal Name"
                            data-cy="client-name"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="displayName"
                            hide-details="auto"
                            disabled
                            label="Client Display Name"
                            data-cy="display-name"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="districtName"
                            label="District Name"
                            hide-details="auto"
                            data-cy="district-name"
                            required
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <school-year
                            v-model="form.data.sisYear"
                            :high-year="highYear"
                            :num-years="rolloverTime ? 2 : 1"
                            label="Data Year"
                            data-cy="sis-year"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-switch
                            v-model="form.data.enabled"
                            class="pb-4"
                            hide-details="auto"
                            label="Client Enabled"
                            data-cy="enabled"
                            inset
                            disabled
                        />
                    </v-col>
                    <v-col class="pb-0">
                        <h5>District Identifiers</h5>
                    </v-col>
                    <v-col class="py-0">
                        <client-search
                            v-model="client"
                            label="Illuminate Client"
                            hide-details="auto"
                            data-cy="client-search"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            :form="form"
                            field-key="id"
                            label="SIS District ID"
                            hide-details="auto"
                            data-cy="sis-district-id"
                            disabled
                        />
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <text-field
                            hide-details="auto"
                            disabled
                            :form="form"
                            field-key="sfAccountNumber"
                            label="SF Account Number"
                            data-cy="sf-account-number"
                        />
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <text-field
                            hide-details="auto"
                            disabled
                            :form="form"
                            field-key="crmAccountNumber"
                            label="CRM Account Number"
                            data-cy="crm-account-number"
                        />
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <text-field
                            hide-details="auto"
                            :form="form"
                            disabled
                            field-key="ncesId"
                            label="NCES ID9"
                            data-cy="nces-id"
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12">
                        <text-field
                            hide-details="auto"
                            :form="form"
                            field-key="lea"
                            label="LEA ID"
                            :disabled="edit"
                            data-cy="lea-id"
                            required
                        >
                            <template #append>
                                <a
                                    class="pt-1"
                                    target="_blank"
                                    href="https://nces.ed.gov/ccd/districtsearch/"
                                >
                                    Lookup
                                </a>
                            </template>
                        </text-field>
                    </v-col>
                    <v-col class="pb-0">
                        <h5>District Address</h5>
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            :form="form"
                            field-key="address"
                            data-cy="address"
                            outlined
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <auto-complete
                            :form="form"
                            field-key="state"
                            hide-details="auto"
                            label="State"
                            :items="getStates"
                            item-text="name"
                            item-value="abbr"
                            :rules="form.rules.state"
                            data-cy="state"
                            outlined
                            required
                            disabled
                        ></auto-complete>
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <text-field
                            disabled
                            :form="form"
                            field-key="county"
                            hide-details="auto"
                            data-cy="county"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            :form="form"
                            field-key="phoneNumber"
                            label="Phone Number"
                            data-cy="phone-number"
                        />
                    </v-col>
                </v-col>
                <v-col md="6" cols="12">
                    <v-col class="pb-0">
                        <h5>District Integration Contact</h5>
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            :form="form"
                            hide-details="auto"
                            field-key="contactFirstName"
                            label="First Name"
                            data-cy="contact-first-name"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            :form="form"
                            hide-details="auto"
                            field-key="contactLastName"
                            label="Last Name"
                            data-cy="contact-last-name"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            :form="form"
                            hide-details="auto"
                            field-key="contactEmailAddress"
                            label="Email Address"
                            data-cy="contact-email-address"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            :form="form"
                            hide-details="auto"
                            field-key="contactPhoneNumber"
                            label="Phone"
                            data-cy="contact-phone-number"
                        />
                    </v-col>
                    <v-col>
                        <h5>Login Page Quote (Overlay Text)</h5>
                        <text-area
                            label="Staff Portal"
                            data-cy="staff-portal"
                            disabled
                            outlined
                        ></text-area>
                        <text-area
                            label="Student/Parent Portal"
                            data-cy="student-parent-portal"
                            disabled
                            outlined
                        ></text-area>
                    </v-col>
                    <v-col class="pb-0">
                        <h5>Options</h5>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-switch
                            v-model="form.data.blockImports"
                            class="mt-1"
                            hide-details="auto"
                            label="Block Imports"
                            data-cy="block-imports"
                            inset
                        />
                    </v-col>
                </v-col>
            </v-row>
        </extended-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextField from '@/components/form/TextField'
import { between, length } from '@/helpers/form/validation'
import ExtendedForm from '@/components/form/ExtendedForm'
import { formSnackbar } from '@/helpers/snackbar'
import AutoComplete from '@/components/form/AutoComplete'
import TextArea from '@/components/form/TextArea'
import ClientSearch from '@/components/form/ClientSearch'
import SchoolYear from '@/components/form/SchoolYear'
import { rolloverTime, currentSchoolYear } from '@/helpers/form/formatting'

export default {
    components: {
        ExtendedForm,
        TextField,
        AutoComplete,
        TextArea,
        ClientSearch,
        SchoolYear,
    },
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            client: null,
            form: {
                ready: false,
                errors: {},
                rules: {
                    state: [(v) => between(v, 0, 50)],
                    districtName: [(v) => !!v || 'District name must be provided'],
                    lea: [
                        (v) => length(v, 7),
                        (v) => !!v || 'Lea Id must be provided',
                        (v) => !!this.edit || !this.getItemById(v, 'lea') || 'LEA must be unique',
                    ],
                },
                data: {
                    illuminateClientId: null,
                    id: null,
                    lea: null,
                    address: null,
                    phoneNumber: null,
                    contactFirstName: null,
                    contactLastName: null,
                    contactPhoneNumber: null,
                    contactEmailAddress: null,
                    contactEmail: null,
                    districtName: null,
                    name: null,
                    displayName: null,
                    ncesId: null,
                    sfAccountNumber: null,
                    crmAccountNumber: null,
                    state: null,
                    county: null,
                    enabled: false,
                    blockImports: false,
                    sisYear: null,
                },
            },
            rolloverTime: rolloverTime(),
            highYear: currentSchoolYear(),
        }
    },
    computed: {
        ...mapGetters('usStates', ['getStates']),
        ...mapGetters('district', ['getItemById', 'getErrors']),
        ...mapGetters({
            districtClient: 'client/getItemById',
            selectedDistrict: 'getSelectedDistrict',
        }),
        ...mapGetters(['getDistrictLea']),
    },
    watch: {
        client() {
            this.form = {
                ...this.form,
                data: {
                    ...this.form.data,
                    illuminateClientId: this.client?.id,
                    state: this.client?.state,
                    displayName: this.client?.displayName,
                    name: this.client?.name,
                    sfAccountNumber: this.client?.sfAccountNumber,
                    crmAccountNumber: this.client?.crmAccountNumber,
                    county: this.client?.county,
                    logoURL: this.client?.logoURL,
                    ncesId: this.client?.ncesId,
                    lea: this.edit ? this.selectedDistrict.lea : this.client?.ncesId,
                    // when we merge the concept of clients with districts, having the district name
                    // be the same as
                    districtName: this.edit
                        ? this.selectedDistrict.districtName
                        : this.client?.displayName,
                    enabled: this.client?.status === 'enabled',
                },
            }
        },
    },
    async created() {
        // If we're not editing we wont have this information.
        if (this.edit) {
            const district = await this.getItemById(this.selectedDistrict.id)
            if (this.selectedDistrict.illuminateClientId) {
                this.client = this.districtClient(this.selectedDistrict.illuminateClientId)
            }

            const formData = {}
            Object.keys(this.form.data).forEach((key) => {
                if (key === 'enabled') {
                    formData.enabled = this.client?.status === 'enabled'
                } else if (key === 'sisYear') {
                    // use numeric value if value is numeric already. prevents type issues.
                    formData[key] = parseInt(district[key]) ?? district[key]
                } else if (district[key]) {
                    formData[key] = district[key]
                } else if (this.client && this.client[key]) {
                    formData[key] = this.client[key]
                }
            })
            this.setFormData(formData)
        }
    },
    methods: {
        ...mapActions(['select']),
        ...mapActions({
            updateDistrict: 'district/patch',
            createDistrict: 'district/post',
            fetchClient: 'client/get',
        }),
        validate() {
            return this.$refs.formRef.validate()
        },
        async submitNew() {
            const data = {
                ...this.form.data,
            }
            const result = await this.createDistrict({
                lea: this.form.data.lea,
                data,
            })

            await this.handleResult(result.data.addDistrict)
        },
        async submitEdit() {
            const data = {
                ...this.form.data,
            }

            const result = await this.updateDistrict({
                id: this.selectedDistrict.id,
                data: data,
            })

            await this.handleResult(result.data.updateDistrict)
        },
        async handleResult(district) {
            if (this.getErrors.length) {
                const errors = this.getErrors[0] || null

                this.form.errors = errors.details
            } else {
                const districtId = district.id

                //fetch the client after the form has been submitted so we can update the store
                if (this.client) {
                    await this.fetchClient(this.client.id)
                }

                // force current district state to update.
                await this.select(this.getItemById(districtId))
                await formSnackbar({
                    isEdit: true,
                    type: 'District',
                    identifier: districtId,
                })

                // editing is done in a modal
                // so no need to push a route
                if (!this.edit) {
                    await this.$router.push({
                        name: 'district-profile',
                        params: { districtId },
                    })
                }
            }
        },
        setFormData(formData) {
            Object.assign(this.form.data, formData)
            this.form.ready = true
        },
    },
}
</script>
