import { humanizeStr } from '@/helpers/form/formatting'

const labelMixin = {
    props: {
        label: {
            type: String,
            default: null,
        },
        fieldKey: {
            type: String,
            default: '',
        },
    },
    computed: {
        getLabel() {
            return this.label ? this.label : humanizeStr(this.fieldKey)
        },
    },
}

export default labelMixin
